<template>
  <div class="page form mailbox">
    <carbo-heading title="Create Mailbox" subtitle="Please, provide following details, to create new mailbox"/>
    <form @submit.prevent="onCreate">
      <mailbox-fields v-model="model" :errors="errors"/>
      <div class="actions">
        <button class="btn">Create</button>
      </div>
    </form>
  </div>
</template>

<script>
  import Fields from './Fields'
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../../store/common'

  const { mapActions } = createNamespacedHelpers('email/mailbox')

  export default {
    name: 'FormMailbox',
    data () {
      return {
        model: {
          email: '',
          status: 'disabled',
          password: '',
          repeatPassword: '',
          created_by: this.$store.getters['auth/userId']
        },
        errors: {}
      }
    },
    components: {
      mailboxFields: Fields
    },
    methods: {
      async onCreate() {
        try {
          await this.create({ model: this.model })
          await this.$router.push('/e-mail/mailbox')
          await this.$showToast('Mailbox successfully created!', 'success')
        } catch (e) {
          if (!e.response) throw e

          this.errors = e.response.data.errors
        }
      },
      ...mapActions({
        create: COMMON.ACTION.CREATE
      })
    }
  }
</script>
