<template>
  <div class="page form domain">
    <carbo-heading title="Add Domain"
                   subtitle="Add new domain record"/>

    <form @submit.prevent="onSubmit">
      <domain-fields v-model="model" :errors="errors"/>
      <div class="actions">
        <button class="btn">Create</button>
      </div>
    </form>
  </div>
</template>

<script>
  import Fields from './Fields'
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../../store/common'

  const domain = createNamespacedHelpers('email/domain')

  export default {
    name: 'Create',
    components: {
      domainFields: Fields
    },
    data() {
      return {
        model: {
          status: 'disabled',
          created_by: this.$store.getters['auth/userId']
        },
        errors: {}
      }
    },
    methods: {
      async onSubmit() {
        try {
          await this.create({ model: this.model })
          await this.$router.push('/e-mail/domain')
          await this.$showToast('Domain successfully added!', 'success')
        } catch (e) {
          if (!e.response) return
          this.errors = e.response.data.errors
        }
      },
      ...domain.mapActions({
        create: COMMON.ACTION.CREATE
      })
    }
  }
</script>

<style scoped>

</style>
