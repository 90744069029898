<template>
  <div id="app">
    <router-view />
    <toast-notifications />
    <modal-target />
  </div>
</template>

<script>
  import ModalTarget from './Partials/Modal/ModalTarget'
  import ToastNotifications from './Partials/ToastNotifications'

  export default {
    name: 'App',
    components: {
      modalTarget: ModalTarget,
      toastNotifications: ToastNotifications
    }
  }
</script>

<style scoped>

</style>
