<template>
  <div class="page form entry">
    <carbo-heading :title="`Edit content entry of ${board.name}`"
                   subtitle="Update details of content entry"/>

    <form @submit.prevent="onSubmit">
      <entry-fields v-model="model" :errors="errors" />

      <div class="actions">
        <button class="btn">Save changes</button>
      </div>
    </form>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'

  import Fields from './Fields'
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../../store/common'
  import { store } from '../../../../app'

  const entry = createNamespacedHelpers('board/entry')

  export default {
    name: 'Update',
    components: {
      entryFields: Fields
    },
    beforeRouteEnter(to, from, next) {
      let requests = []

      if (!store.getters['board/count'])
        requests.push(store.dispatch('board/fetch'))

      if (!store.getters['board/entry/count'])
        requests.push(store.dispatch('board/entry/fetch')({ slug: to.params.slug }))

      Promise.all(requests)
        .then(() => next())
        .catch(() => next(`/board/${to.params.slug}`))
    },
    data() {
      const board = this.$store.getters['board/itemBySlug'](this.$route.params.slug)
      const model = cloneDeep(this.$store.getters['board/entry/item'](this.$route.params.id))
      model.updated_by = this.$store.getters['auth/userId']

      return {
        board,
        model,
        errors: {}
      }
    },
    methods: {
      async onSubmit() {
        try {
          await this.update({ model: this.model })
          await this.$router.push(`/board/${this.board.slug}/entry`)
          await this.$showToast('Entry updated successfully!', 'success')
        } catch(e) {
          if (!e.response) throw e
          this.errors = e.response.data.errors
        }
      },
      ...entry.mapActions({
        update: COMMON.ACTION.UPDATE
      })
    }
  }
</script>
