<template>
    <div class="page alias">
      <carbo-heading title="Aliases" subtitle="Manage aliases" />

      <div class="actions">
        <router-link to="/e-mail/alias/create" class="btn">Create Alias</router-link>
      </div>

      <carbo-data-table>
        <carbo-data-table-head :columns="['Status', 'Source', 'Destination', 'Created by', 'Last change', 'Actions']" />
        <carbo-data-table-row v-for="alias in aliases" :key="alias.id">
          <td style="width: 100px; font-weight: bold" :class="$classesStatus(alias.status)">{{ $formatStatus(alias.status) }}</td>
          <td>{{ alias.source }}</td>
          <td>{{ alias.destination }}</td>
          <td>{{ $formatUser(alias.created_by) }}</td>
          <td>{{ $formatDate(alias.updated_at) }}</td>
          <td>
            <router-link :to="`/e-mail/alias/update/${alias.id}`">Edit</router-link> |
            <a class="color-failure" href="#delete" @click.prevent="e => toDelete = alias">Delete</a>
          </td>
        </carbo-data-table-row>
      </carbo-data-table>

      <carbo-confirmation v-if="toDelete"
                          accept-label="Delete"
                          accept-variant="failure"
                          :on-accept="onDelete"
                          :on-cancel="onCancel">
        Do you really wish to delete alias from <strong>{{ toDelete.source }}</strong> to
        <strong>{{ toDelete.destination }}</strong>?<br>

        This action <strong>can not</strong> be undone.
      </carbo-confirmation>
    </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../../store/common'
  import format from '../../../../mixins/email/format'

  const alias = createNamespacedHelpers('email/alias')

  export default {
    name: 'Alias',
    mixins: [format],
    beforeMount() {
      this.fetch()
    },
    computed: {
      ...alias.mapState({
        aliases: state => state.data,
        isFetching: state => state.isFetching,
        error: state => state.error
      })
    },
    data() {
      return {
        toDelete: null
      }
    },
    methods: {
      ...alias.mapActions({
        fetch: COMMON.ACTION.FETCH,
        delete: COMMON.ACTION.DELETE
      }),
      async onDelete() {
        const item = this.toDelete
        this.toDelete = null

        try {
          await this.delete({ id: item.id })
          await this.$showToast(`Alias from "${item.source}" to "${item.destination}" deleted successfully!`,
            'success')
        } catch (e) {
          await this.$showToast(`Failed to delete alias: ${e.message}`)
        }
      },
      onCancel() {
        this.toDelete = null
      }
    }
  }
</script>

<style scoped>

</style>
