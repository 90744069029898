<template>
  <div class="master-layout">
    <navigation/>
    <main class="content">
      <router-view />
    </main>
  </div>
</template>

<script>
  import Navigation from '../Partials/Navigation'

  export default {
    name: 'MasterLayout',
    components: {
      'navigation': Navigation
    }
  }
</script>

<style scoped>

</style>
