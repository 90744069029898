<template>
    <tr>
      <th v-for="col in columns" :key="col">{{ col }}</th>
    </tr>
</template>

<script>
  export default {
    name: 'DataTableHead',
    props: {
      columns: Array
    }
  }
</script>

<style scoped>

</style>
