import Vue from 'vue'
import Vuex from 'vuex'

import { getModulesForContext, INDEX_REGEX } from '../common/modules'

Vue.use(Vuex)

const context = require.context('./', true, /index\.module\.js$/)
const createStore = () => {
    return new Vuex.Store({
        modules: getModulesForContext(context),
        strict: process.env.NODE_ENV !== 'production'
    })
}

export default createStore
