<template>
    <div class="page mailbox">
      <carbo-heading title="Mailboxes" subtitle="Manage mailboxes" />

      <div class="actions">
        <router-link to="/e-mail/mailbox/create" class="btn">Create Mailbox</router-link>
      </div>

      <carbo-data-table>
        <carbo-data-table-head :columns="[ 'Status', 'E-mail', 'Created by', 'Last change', 'Actions' ]"/>
        <carbo-data-table-row v-for="mailbox in mailboxes" :key="mailbox.id">
          <td style="width: 100px; font-weight: bold" :class="$classesStatus(mailbox.status)">{{ $formatStatus(mailbox.status) }}</td>
          <td>{{ mailbox.email }}</td>
          <td>{{ $formatUser(mailbox.created_by) }}</td>
          <td>{{ $formatDate(mailbox.updated_at) }}</td>
          <td>
            <router-link :to="`/e-mail/mailbox/update/${mailbox.id}`">Edit</router-link> |
            <a class="color-failure" href="#delete" @click.prevent="(e) => toDelete = mailbox">Delete</a>
          </td>
        </carbo-data-table-row>
      </carbo-data-table>

      <carbo-confirmation v-if="toDelete"
                          accept-variant="failure"
                          accept-label="Delete"
                          :on-accept="onAccept"
                          :on-cancel="onCancel">
        Do you really want to delete mailbox <strong>{{ toDelete.email }}</strong>?<br>
        This action <strong>can not</strong> be undone.
      </carbo-confirmation>
    </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../../store/common'
  import capitalize from 'lodash/capitalize'
  import format from '../../../../mixins/email/format'

  const { mapState, mapActions } = createNamespacedHelpers('email/mailbox')

  export default {
    name: 'Mailbox',
    mixins: [format],
    beforeMount() {
      this.fetch()
    },
    methods: {
      ...mapActions({
        fetch: COMMON.ACTION.FETCH,
        delete: COMMON.ACTION.DELETE
      }),
      async onAccept() {
        const item = this.toDelete
        this.toDelete = null

        try {
          await this.delete({ id: item.id })
          await this.$showToast(`Mailbox "${item.email}" successfully deleted!`, 'success')
        } catch(e) {
          await this.$showToast(`Failed to delete mailbox: ${e.message}`, 'failure')
        }
      },
      onCancel() {
        this.toDelete = null
      }
    },
    computed: {
      ...mapState({
        mailboxes: state => state.data,
        isFetching: state => state.isFetching,
        error: state => state.error
      })
    },
    data() {
      return {
        toDelete: null
      }
    }
  }
</script>

<style scoped>

</style>
