import * as GETTER from './types.getter'
import * as MUTATION from './types.mutation'
import * as ACTION from './types.action'
import { createUser, deleteUser, getUsers, updateUser } from '../../services/settings'

import { USER as AUTH_USER } from '../auth/types.getter'
import { SET_USER as AUTH_SET_USER } from '../auth/types.mutation'
import { setCachedUser } from '../../services/authorization'

export default {
    namespaced: true,
    state: {
        isFetching: false,
        data: [],
        error: null
    },
    getters: {
        [GETTER.DATA]: state => state.data,
        [GETTER.IS_FETCHING]: state => state.isFetching,
        [GETTER.ERROR]: state => state.error,

        [GETTER.USER]: (state) => (id) => state.data.find(user => user.id == id),
        [GETTER.USER_INDEX]: (state) => (id) => state.data.findIndex(user => user.id == id)
    },
    mutations: {
        [MUTATION.REQUEST_FETCH_USERS]: state => {
            state.isFetching = true
        },
        [MUTATION.SUCCESS_FETCH_USERS]: (state, { users }) => {
            state.isFetching = false
            state.data = users
            state.error = null
        },
        [MUTATION.FAILURE_FETCH_USERS]: (state, { error }) => {
            state.isFetching = false
            state.data = null
            state.error = error
        },

        [MUTATION.REQUEST_CREATE_USER]: (state, { user }) => {
            state.data.push(user)
        },
        [MUTATION.SUCCESS_CREATE_USER]: (state, { user }) => {
            state.data.map(existingUser => {
                if (user.email === existingUser.email) return user
                return existingUser
            })
        },
        [MUTATION.FAILURE_CREATE_USER]: (state, { error, user }) => {
            state.data.splice(state.data.indexOf(user), 1)
        },

        [MUTATION.REQUEST_UPDATE_USER]: (state, { user, index }) => {
            state.data[index] = user
        },
        [MUTATION.SUCCESS_UPDATE_USER]: (state, { user, index }) => {
            state.data[index] = user
        },
        [MUTATION.FAILURE_UPDATE_USER]: (state, { error, user, index }) => {
            state.data[index] = user
        },

        [MUTATION.REQUEST_DELETE_USER]: (state, { index }) => {
            state.data.splice(index, 1)
        },
        [MUTATION.SUCCESS_DELETE_USER]: (state) => {},
        [MUTATION.FAILURE_DELETE_USER]: (state, { error, user, index }) => {
            state.data = [...state.data.slice(0, index), user, ...state.data.slice(index)]
        }
    },
    actions: {
        async [ACTION.FETCH_USERS]({ commit }) {
            commit(MUTATION.REQUEST_FETCH_USERS)

            try {
                const response = await getUsers()
                commit(MUTATION.SUCCESS_FETCH_USERS, { users: response.data })

                return response
            } catch (e) {
                commit(MUTATION.FAILURE_FETCH_USERS, { error: e.message })
                throw e
            }
        },
        async [ACTION.CREATE_USER]({ commit }, { model }) {
            commit(MUTATION.REQUEST_CREATE_USER, { user: model })

            try {
                const response = await createUser(model)
                commit(MUTATION.SUCCESS_CREATE_USER, { user: response.data })

                return response
            } catch (e) {
                commit(MUTATION.FAILURE_CREATE_USER, { error: e.message, user: model })
                throw e
            }
        },
        async [ACTION.UPDATE_USER]({ commit, getters, rootGetters }, { model }) {
            const userIndex = getters[GETTER.USER_INDEX](model.id)
            const userModel = getters[GETTER.USER](model.id)

            commit(MUTATION.REQUEST_UPDATE_USER, { user: model, index: userIndex })

            try {
                const response = await updateUser(model)
                commit(MUTATION.SUCCESS_UPDATE_USER, { user: response.data, index: userIndex })

                if (rootGetters[`auth/${AUTH_USER}`].id === response.data.id) {
                    commit(`auth/${AUTH_SET_USER}`, { user: response.data }, { root: true })
                    setCachedUser(response.data)
                }

                return response
            } catch (e) {
                commit(MUTATION.FAILURE_UPDATE_USER, { error: e.message, user: userModel, index: userIndex })
                throw e
            }
        },
        async [ACTION.DELETE_USER]({ commit, getters }, { id }) {
            const userModel = getters[GETTER.USER](id)
            const userIndex = getters[GETTER.USER_INDEX](id)

            commit(MUTATION.REQUEST_DELETE_USER, { index: userIndex })

            try {
                const response = await deleteUser(id)
                commit(MUTATION.SUCCESS_DELETE_USER)

                return response
            } catch(e) {
                commit(MUTATION.FAILURE_DELETE_USER, { error: e.message, user: userModel, index: userIndex })
                throw e
            }
        }
    }
}
