import MasterLayout from '../components/Layouts/MasterLayout'
import Permissions from '../permissions'

const manageBoards = [ Permissions.informationBoard.MANAGE_BOARDS ]
const manageEntries = [ Permissions.informationBoard.MANAGE_BOARDS, Permissions.informationBoard.MANAGE_ENTRIES ]

import BoardList from '../components/Pages/Board/List'
import BoardCreate from '../components/Pages/Board/Create'
import BoardUpdate from '../components/Pages/Board/Update'

import EntryList from '../components/Pages/Board/Entry/List'
import EntryCreate from '../components/Pages/Board/Entry/Create'
import EntryUpdate from '../components/Pages/Board/Entry/Update'

export default [
    {
        path: '/board',
        component: MasterLayout,
        meta: {
            auth: true
        },
        children: [
            {
                path: '/board',
                component: BoardList,
                meta: {
                    permissions: manageBoards
                }
            },
            {
                path: '/board/create',
                component: BoardCreate,
                meta: {
                    permissions: manageBoards
                }
            },
            {
                path: '/board/update/:id',
                component: BoardUpdate,
                meta: {
                    permissions: manageBoards
                }
            },

            {
                path: '/board/:slug/entry',
                component: EntryList,
                meta: {
                    permissions: manageEntries
                }
            },
            {
                path: '/board/:slug/entry/create',
                component: EntryCreate,
                meta: {
                    permissions: manageEntries
                }
            },
            {
                path: '/board/:slug/entry/update/:id',
                component: EntryUpdate,
                meta: {
                    permissions: manageEntries
                }
            }
        ]
    }
]
