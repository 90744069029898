import axios from 'axios'
import difference from 'lodash/difference'
import { transformUser } from '../common/transform'

const KEY_USER = 'carbo_user'
const KEY_TOKEN = 'carbo_token'

export const getCachedUser = () => {
    if (!localStorage.getItem(KEY_USER)) return null
    return JSON.parse(localStorage.getItem(KEY_USER))
}

export const getAuthorizationToken = () => {
    if (!localStorage.getItem(KEY_TOKEN)) return null
    return JSON.parse(localStorage.getItem(KEY_TOKEN))
}

export const setCachedUser = (user) => {
    localStorage.setItem(KEY_USER, JSON.stringify(user))
}

const setAuthorizationToken = (token) => {
    localStorage.setItem(KEY_TOKEN, JSON.stringify(token))
}

export const clearCache = () => {
    localStorage.removeItem(KEY_TOKEN)
    localStorage.removeItem(KEY_USER)
}

export const login = async (credentials) => {
    try {
        const response = await axios.post('/api/auth/login', credentials)

        response.data.user = transformUser(response.data.user)

        setCachedUser(response.data.user)
        setAuthorizationToken(response.data.token)

        return response
    } catch(e) {
        throw e
    }
}

export const me = async () => {
    const response = await axios.post('/api/auth/me')

    setCachedUser(response.data)

    return response
}

export const refresh = async () => {
    const response = await axios.post('/api/auth/refresh')

    setAuthorizationToken(response.data.token)

    return response
}

export const logout = async () => {

    try {
        return await axios.post('/api/auth/logout')
    } catch (e) {
    }
}

export const hasPermission = (user, permission) => {
    return !!user.permissions.find(i => i === permission)
}

export const containsPermissions = (user, permissions) => {
    if (!permissions || !permissions.length) return true
    return difference(permissions, user.permissions).length === 0
}

export const guardAuth = (to, from, next) => {
    if (!hasAuthFlag(to.matched)) return next()

    if (getAuthorizationToken()) next()
    else if (!!from.matched.length) next(false)
    else next('/login')
}

export const guardNoAuth = (to, from, next) => {
    if (!hasNoAuthFlag(to.matched)) return next()

    if (!getAuthorizationToken()) next()
    else if (!!from.matched.length) next(false)
    else next('/')
}

export const guardPermissions = (to, from, next) => {
    const permissions = getRequiredPermissions(to.matched)

    if (containsPermissions(getCachedUser(), permissions)) next()
    else next(false)
}

const hasAuthFlag = (routeRecords) => {
    return routeRecords.reduce((acc, current) => acc || current.meta.auth, false)
}

const hasNoAuthFlag = (routeRecords) => {
    return routeRecords.reduce((acc, current) => acc || current.meta.noAuth, false)
}

const getRequiredPermissions = (routeRecords) => {
    return routeRecords.reduce((acc, current) =>
            (current.meta && current.meta.permissions) ? acc.concat(current.meta.permissions) : acc,
        [])
}
