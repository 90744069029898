const NOT_AVAILABLE = 'N/A'
const FETCHING = 'Fetching...'
const ERROR = 'Error occured!'

export const $formatDate = (dateString) => {
    if (!dateString) return NOT_AVAILABLE

    const options = {}
    return (new Date(dateString)).toLocaleDateString('sk', options)
}

let usersFetchPromise = null
export const $formatUser = function (userId) {
    const user = this.$store.getters['users/user'](userId)

    if (this.$store.state.users.error) {
        return ERROR
    }
    if (!this.$store.state.users.data.length) {
        if (!usersFetchPromise) usersFetchPromise = this.$store.dispatch('users/fetchUsers')
        
        usersFetchPromise
            .then(() => this.$forceUpdate())
            .catch(() => this.$forceUpdate())

        return FETCHING
    }
    if (!user) return NOT_AVAILABLE
    return `${user.name} (${user.email})`
}

export default {
    methods: {
        $formatDate,
        $formatUser
    }
}
