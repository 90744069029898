export const getModulesForContext = (context) => {
    return context.keys().reduce((acc, fileName) => {
        if (!/^\.(\/[^\/]+){2}$/.test(fileName)) return acc

        const moduleName = fileName.match(/(\.\/)([^\/]+)(?=\/)/)[2]
        acc[moduleName] = context(fileName).default

        return acc
    }, {})
}
