<template>
    <div class="page login text-center">
      <h1 class="brand-name text-uppercase">Carbo</h1>
      <h2 class="content-subtitle">Enter your credentials to continue.</h2>
      <form :state="formState" @submit.prevent="onSubmit" class="form" style="margin-top: 2rem">
        <div class="form-group">
          <label for="email">E-mail:</label>
          <input type="email" id="email" v-model="model.email" class="form-control" name="email" required />
        </div>

        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="model.password" class="form-control" name="password" required />
        </div>

        <div class="actions">
          <button class="btn">Login</button>
        </div>
      </form>
    </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { LOGIN } from '../../store/auth/types.action'

  const auth = createNamespacedHelpers('auth')

  export default {
    name: 'Login',
    data() {
      return {
        formState: {},
        model: {
          email: '',
          password: ''
        }
      }
    },
    methods: {
      async onSubmit() {
        try {
          await this.login({ credentials: this.model })
          await this.$router.push('/')
          await this.$showToast('Login successful!', 'success')
        } catch (e) {
          await this.$showToast(`Login failed: ${e.message}`, 'failure')
        }
      },
      ...auth.mapActions({
        login: LOGIN
      })
    }
  }
</script>

<style lang="scss" scoped>
  .page.login {
    transform: translate(-50%, -50%);
    padding: 2rem;
    background-color: #eee;
    left: 50%;
    top: 50%;
    position: absolute;
    width: 320px;
  }
</style>
