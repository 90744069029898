import Permissions from '../../permissions'

export default {
    boards: {
        title: 'Information boards',
        items: [
            { title: 'Boards', link: '/board', permissions: [ Permissions.informationBoard.MANAGE_BOARDS ] }
        ]
    }
}
