import { USER } from '../store/auth/types.getter'
import permissions from '../permissions'

export default {
    computed: {
        $permissions: () => permissions
    },
    methods: {
        $hasPermission(permissionString) {
            const user = this.$store.getters[`auth/${USER}`]
            return user.permissions.indexOf(permissionString) !== -1
        }
    }
}
