<template>
    <div class="fields">
      <carbo-form-field name="email" type="email" label="E-mail" v-model="model.email" />
      <carbo-form-validation-error v-if="errors.email" :errors="errors.email" />

      <carbo-form-select name="status" label="Status"
                         v-model="model.status"
                         :options="[
                           {value: 'enabled', label: 'Enabled'},
                           {value: 'disabled', label: 'Disabled'}]"/>
      <carbo-form-validation-error v-if="errors.status" :errors="errors.status" />

      <carbo-form-field name="password" type="password" label="Password" v-model="model.password" />
      <carbo-form-validation-error v-if="errors.password" :errors="errors.password" />

      <carbo-form-field name="password-repeat" type="password" label="Repeat password" v-model="model.repeatPassword" />
      <carbo-form-validation-error v-if="errors.repeatPassword" :errors="errors.repeatPassword" />
    </div>
</template>

<script>
  export default {
    name: 'Fields',
    props: {
      value: Object,
      errors: Object
    },
    data() {
      return {
        model: this.value
      }
    },
    methods: {
      onModelChanged() {
        this.$emit('input', this.model)
      }
    }
  }
</script>

<style scoped>

</style>
