<template>
    <div class="toast-notifications">
      <div v-for="toast in toasts" class="toast" :class="{ [toast.variant]: true }">
        {{ toast.message }}
      </div>
    </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const toasts = createNamespacedHelpers('toasts')

  export default {
    name: 'ToastNotifications',
    computed: {
      ...toasts.mapState({
        toasts: state => state.toasts
      })
    },
  }
</script>

<style scoped>

</style>
