<template>
  <div class="page form board">
    <carbo-heading title="Create Information Board"
                   subtitle="Please, provide following information, to create new Information Board"/>

    <form @submit.prevent="onSubmit">
      <board-fields v-model="model" :errors="errors" />

      <div class="actions">
        <button class="btn">Create Information Board</button>
      </div>
    </form>
  </div>
</template>

<script>
  import Fields from './Fields'
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../store/common'

  const board = createNamespacedHelpers('board')

  export default {
    name: 'Create',
    components: {
      boardFields: Fields
    },
    data() {
      return {
        model: {
          slug: Math.random().toString(36).slice(2),
          created_by: this.$store.getters['auth/userId']
        },
        errors: {}
      }
    },
    methods: {
      async onSubmit() {
        try {
          await this.create({ model: this.model })
          await this.$router.push('/board')
          await this.$showToast('Board created successfully!', 'success')
        } catch (e) {
          if (!e.response) throw e
          this.errors = e.response.data.errors
        }
      },
      ...board.mapActions({
        create: COMMON.ACTION.CREATE
      })
    }
  }
</script>
