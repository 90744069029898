<template>
  <div class="page form user" v-if="this.model">
    <carbo-heading :title="`Edit user ${originalName}`"
                   subtitle="Change user details" />
    <form @submit.prevent="onSubmit">
      <user-fields v-model="model" :errors="errors" />
      <div class="actions">
        <button class="btn">Save changes</button>
      </div>
    </form>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'

  import Fields from './Fields'
  import { createNamespacedHelpers } from 'vuex'
  import { FETCH_USERS, UPDATE_USER } from '../../../store/users/types.action'

  import { store } from '../../../app'

  const users = createNamespacedHelpers('users')

  export default {
    name: 'Update',
    beforeRouteEnter(to, from, next) {
      if (store.state.users.data.length <= 0) {
        store.dispatch(`users/${FETCH_USERS}`).then(response => {
          next()
        }, error => {
          next('/settings')
        })
      }
      else next()
    },
    data() {
      const model = cloneDeep(this.$store.state.users.data.find(i => i.id == this.$route.params.id))
      
      return {
        originalName: model.name,
        model,
        errors: {}
      }
    },
    components: {
      'user-fields': Fields
    },
    methods: {
      ...users.mapActions({
        updateUser: UPDATE_USER,
        fetchUsers: FETCH_USERS
      }),
      async onSubmit () {
        try {
          await this.updateUser({ model: cloneDeep(this.model) })
          await this.$router.push('/settings')
          await this.$showToast(`User ${ this.model.name } successfully updated.`, 'success')
        } catch (e) {
          if (!e.response) throw e
          this.errors = e.response.data.errors
        }
      }
    }
  }
</script>

<style scoped>

</style>
