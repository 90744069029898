<template>
  <div class="tab">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'Tab',
    props: {
      title: String,
      disabled: Boolean
    },
    data() {
      return {}
    }
  }
</script>

<style scoped>

</style>
