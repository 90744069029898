<template>
    <div class="pagination">
      <div
        v-for="page in pagination"
        :class="{ active: selectedIndex === page }"
        :key="page"
        @click="e => onSelect(page)">
        {{ typeof(page) === 'number' ? page + 1 : page }}
      </div>
    </div>
</template>

<script>
  import range from 'lodash/range'

  export const NEXT = '>>'
  export const PREV = '<<'

  export default {
    name: 'Pagination',
    props: {
      selectedIndex: Number,
      pageCount: Number,
      onSelect: Function,

      displayNumbers: {
        type: Number,
        default: 7
      },
      displayNeighbours: {
        type: Number,
        default: 1
      }
    },
    computed: {
      pagination () {
        const totalNumbers = (this.displayNeighbours * 2) + 3

        if (this.displayNumbers < this.pageCount) {
          const startPage = Math.max(1, this.selectedIndex - this.displayNeighbours)
          const endPage = Math.min(this.pageCount-1, this.selectedIndex + this.displayNeighbours + 1)

          let pages = range(startPage, endPage)

          const hasLeftSpill = startPage > 1;
          const hasRightSpill = (this.pageCount - endPage) > 1
          const spillOffset = totalNumbers - (pages.length + 1)

          let extraPages;

          switch (true) {
            case (hasLeftSpill && !hasRightSpill):
              extraPages = range(startPage - spillOffset, startPage)
              pages = [PREV, ...extraPages, ...pages]
              break;
            case (hasRightSpill && !hasLeftSpill):
              extraPages = range(endPage, endPage + spillOffset)
              pages = [...pages, ...extraPages, NEXT]
              break;
            case (hasLeftSpill && hasRightSpill):
            default:
              pages = [PREV, ...pages, NEXT]
              break;
          }

          return [0, ...pages, this.pageCount-1];
        }

        return range(this.pageCount)
      }
    }
  }
</script>

<style scoped>

</style>
