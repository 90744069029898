<template>
  <div class="page form entry">
    <carbo-heading :title="`Add new content entry to ${board.name}`"
                   subtitle="Please, provide following details, to create new entry" />

    <form @submit.prevent="onSubmit">
      <entry-fields v-model="model" :errors="errors" />

      <div class="actions">
        <button class="btn">Create entry</button>
      </div>
    </form>
  </div>
</template>

<script>
  import Fields from './Fields'
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../../store/common'
  import { store } from '../../../../app'

  const entry = createNamespacedHelpers('board/entry')

  export default {
    name: 'Create',
    components: {
      entryFields: Fields
    },
    beforeRouteEnter(to, from, next) {
      if (!store.getters['board/count']) {
        store.dispatch('board/fetch')
          .then(() => next())
          .catch(() => next('/board'))
      }
      else next()
    },
    data() {
      const board = this.$store.getters['board/itemBySlug'](this.$route.params.slug)

      return {
        board,
        model: {
          sort_order: 9999,
          board_id: board.id,
          status: 'info',
          created_by: this.$store.getters['auth/userId']
        },
        errors: {}
      }
    },
    methods: {
      async onSubmit() {
        try {
          await this.create({ model: this.model })
          await this.$router.push(`/board/${this.$route.params.slug}/entry`)
          await this.$showToast('Entry created successfully!', 'success')
        } catch(e) {
          if (!e.response) throw e
          this.errors = e.response.data.errors
        }
      },
      ...entry.mapActions({
        create: COMMON.ACTION.CREATE
      })
    }
  }
</script>
