import axios from 'axios'

// Mailboxes

export const getMailboxes = async () => {
    return await axios.get('/api/email/mailbox')
}

export const createMailbox = async ({ model }) => {
    return await axios.post('/api/email/mailbox/create', model)
}

export const updateMailbox = async ({ model }) => {
    return await axios.patch(`/api/email/mailbox/update/${model.id}`, model)
}

export const deleteMailbox = async ({ id }) => {
    return await axios.delete(`/api/email/mailbox/delete/${id}`)
}

// Aliases

export const getAliases = async () => {
    return await axios.get('/api/email/alias')
}

export const createAlias = async ({ model }) => {
    return await axios.post('/api/email/alias/create', model)
}

export const updateAlias = async ({ model }) => {
    return await axios.patch(`/api/email/alias/update/${model.id}`, model)
}

export const deleteAlias = async ({ id }) => {
    return await axios.delete(`/api/email/alias/delete/${id}`)
}

// Domains

export const getDomains = async () => {
    return await axios.get('/api/email/domain')
}

export const createDomain = async ({ model }) => {
    return await axios.post('/api/email/domain/create', model)
}

export const updateDomain = async ({ model }) => {
    return await axios.patch(`/api/email/domain/update/${model.id}`, model)
}

export const deleteDomain = async ({ id }) => {
    return await axios.delete(`/api/email/domain/delete/${id}`)
}
