import merge from 'lodash/merge'
import has from 'lodash/has'

import createCrudStore from '../../common/crud.store'
import { createEntry, deleteEntry, getEntries, sortEntries, updateEntry } from '../../../services/informationBoard'
import { FAILURE_SORT, REQUEST_SORT, SUCCESS_SORT } from './types.mutation'
import { SORT } from './types.action'
import { SORT_ORDER } from './types.getter'

const crudStore = createCrudStore(getEntries, createEntry, updateEntry, deleteEntry)

export default merge(crudStore, {
    namespaced: true,
    getters: {
        [SORT_ORDER]: state => state.data.reduce((acc, current) => {
            acc[current.id] = current.sort_order
            return acc
        }, {})
    },
    mutations: {
        [REQUEST_SORT]: (state, { sortOrder }) => {
            state.data = state.data.map(i => {
                if (!has(sortOrder, i.id)) return i

                i.sort_order = sortOrder[i.id]
                return i
            })
        },
        [SUCCESS_SORT]: (state) => {},
        [FAILURE_SORT]: (state, { originalSortOrder }) => {
            state.data = state.data.map(i => {
                if (!has(originalSortOrder, i.id)) return i

                i.sort_order = originalSortOrder[i.id]
                return i
            })
        }
    },
    actions: {
        async [SORT]({ commit, getters }, { sortOrder }) {
            const originalSortOrder = getters[SORT_ORDER]

            commit(REQUEST_SORT, { sortOrder })

            try {
                const response = await sortEntries({ sortOrder })
                commit(SUCCESS_SORT)

                return response
            } catch (e) {
                commit(FAILURE_SORT, { originalSortOrder })
                throw e
            }
        }
    }
})
