<template>
    <tr>
      <slot />
    </tr>
</template>

<script>
  export default {
    name: 'DataTableRow'
  }
</script>

<style scoped>

</style>
