<template>
  <div class="page form mailbox">
    <carbo-heading  :title="`Edit mailbox ${model.email}`" subtitle="Change mailbox details" />
    <form @submit.prevent="onSubmit">
      <mailbox-fields v-model="model" :errors="errors" />
      <div class="actions">
        <button class="btn">Save changes</button>
      </div>
    </form>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'

  import Fields from './Fields'
  import COMMON from '../../../../store/common'
  import { createNamespacedHelpers } from 'vuex'
  import { store } from '../../../../app'

  const mailbox = createNamespacedHelpers('email/mailbox')

  export default {
    name: 'Update',
    components: {
      mailboxFields: Fields
    },
    beforeRouteEnter(to, from, next) {
      if (!store.getters['email/mailbox/count']) {
        store.dispatch('email/mailbox/fetch')
          .then(() => next())
          .catch(() => next('/e-mail/mailbox'))
      }
      else next()
    },
    data() {
      const model = cloneDeep(this.$store.getters['email/mailbox/item'](this.$route.params.id))
      model.updated_by = this.$store.getters['auth/userId']
      
      return {
        model,
        errors: {}
      }
    },
    methods: {
      ...mailbox.mapActions({
        fetch: COMMON.ACTION.FETCH,
        update: COMMON.ACTION.UPDATE
      }),
      async onSubmit() {
        try {
          await this.update({ model: cloneDeep(this.model) })
          await this.$router.push('/e-mail/mailbox')
          await this.$showToast('Mailbox updated successfully!', 'success')
        } catch (e) {
          if (!e.response) throw e
          this.errors = e.response.data.errors
        }
      }
    }
  }
</script>
