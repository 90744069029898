export const REQUEST_FETCH = 'requestFetch'
export const SUCCESS_FETCH = 'successFetch'
export const FAILURE_FETCH = 'failureFetch'

export const REQUEST_CREATE = 'requestCreate'
export const SUCCESS_CREATE = 'successCreate'
export const FAILURE_CREATE = 'failureCreate'

export const REQUEST_UPDATE = 'requestUpdate'
export const SUCCESS_UPDATE = 'successUpdate'
export const FAILURE_UPDATE = 'failureUpdate'

export const REQUEST_DELETE = 'requestDelete'
export const SUCCESS_DELETE = 'successDelete'
export const FAILURE_DELETE = 'failureDelete'
