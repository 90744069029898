<template>
    <div class="form-group">
      <label>{{ label }}:</label>
      <div class="form-control multi-assign">
        <div class="item" v-for="option in options">
          <input type="checkbox" :id="option.value" :value="option.value"
                 :checked="value.findIndex(i => i === option.value) !== -1"
                 @change="e => onAssign(option.value, e.target.checked)">
          <label :for="option.value">{{ option.label }}</label>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'FormMultiAssign',
    props: {
      name: String,
      label: String,
      options: Array,
      value: Array
    },
    methods: {
      onAssign (item, enabled) {
        if (enabled) {
          const newArray = this.value.concat(item)
          this.$emit('input', newArray)
        } else {
          const index = this.value.findIndex(i => i === item)
          const newArray = [
            ...this.value.slice(0, index),
            ...this.value.slice(index + 1)
          ]
          this.$emit('input', newArray)
        }
      }
    }
  }
</script>

<style scoped>

</style>
