import merge from 'lodash/merge'

import { getModulesForContext } from '../../common/modules'
import createCrudStore from '../common/crud.store'
import { createBoard, deleteBoard, getBoards, updateBoard } from '../../services/informationBoard'

import * as GETTER from './types.getter'

const crudStore = createCrudStore(getBoards, createBoard, updateBoard, deleteBoard);
const context = require.context('./', true, /index\.module\.js$/)
export default merge(crudStore, {
    namespaced: true,
    modules: getModulesForContext(context),
    getters: {
        [GETTER.ITEM_BY_SLUG]: state => slug => state.data.find(i => i.slug === slug),
        [GETTER.ITEM_INDEX_BY_SLUG]: state => slug => state.data.findIndex(i => i.slug === slug)
    }
})
