import Vue from 'vue'

import Heading from './Partials/Heading'
import Preloader from './Partials/Preloader'

import Tabs from './Partials/Tabs/Tabs'
import Tab from './Partials/Tabs/Tab'

import DataTable from './Partials/DataTable/DataTable'
import DataTableHead from './Partials/DataTable/DataTableHead'
import DataTableRow from './Partials/DataTable/DataTableRow'

import Pagination from './Partials/Pagination/Pagination'

import FormField from './Partials/Form/FormField'
import FormMultiAssign from './Partials/Form/FormMultiAssign'
import FormSelect from './Partials/Form/FormSelect'
import FormValidationError from './Partials/Form/FormValidationError'

import Modal from './Partials/Modal/Modal'
import ConfirmationModal from './Partials/Modal/ConfirmationModal'

Vue.component('carbo-heading', Heading)
Vue.component('carbo-preloader', Preloader)

Vue.component('carbo-tabs', Tabs)
Vue.component('carbo-tab', Tab)

Vue.component('carbo-data-table', DataTable)
Vue.component('carbo-data-table-head', DataTableHead)
Vue.component('carbo-data-table-row', DataTableRow)

Vue.component('carbo-pagination', Pagination)

Vue.component('carbo-form-field', FormField)
Vue.component('carbo-form-multi-assign', FormMultiAssign)
Vue.component('carbo-form-select', FormSelect)
Vue.component('carbo-form-validation-error', FormValidationError)

Vue.component('carbo-modal', Modal)
Vue.component('carbo-confirmation', ConfirmationModal)
