<template>
  <div class="page form domain">
    <carbo-heading :title="`Edit domain ${model.domain}`"
                   subtitle="Update domain details" />

    <form @submit.prevent="onSubmit">
      <domain-fields v-model="model" :errors="errors" />

      <div class="actions">
        <button class="btn">Save changes</button>
      </div>
    </form>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'

  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../../store/common'
  import Fields from './Fields'
  import { store } from '../../../../app'

  const domain = createNamespacedHelpers('email/domain')

  export default {
    name: 'Update',
    beforeRouteEnter(to, from, next) {
      if (!store.getters['email/domain/count']) {
        store.dispatch('email/domain/fetch')
          .then(() => next())
          .catch(() => next('/e-mail/domain'))
      }
      else next()
    },
    components: {
      domainFields: Fields
    },
    data() {
      const model = cloneDeep(this.$store.getters['email/domain/item'](this.$route.params.id))
      model.updated_by = this.$store.getters['auth/userId']
      
      return {
        model,
        errors: {}
      }
    },
    methods: {
      async onSubmit() {
        try {
          await this.update({ model: this.model })
          await this.$router.push('/e-mail/domain')
          await this.$showToast('Domain updated successfully!', 'success')
        } catch (e) {
          if (!e.response) throw e
          this.errors = e.response.data.errors
        }
      },
      ...domain.mapActions({
        update: COMMON.ACTION.UPDATE
      })
    }
  }
</script>

<style scoped>

</style>
