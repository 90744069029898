import { getAuthorizationToken, getCachedUser, login, logout } from '../../services/authorization'

import * as ACTION from './types.action'
import * as MUTATION from './types.mutation'
import * as GETTER from './types.getter'

export default {
    namespaced: true,
    state: {
        user: getCachedUser(),
        token: getAuthorizationToken()
    },
    getters: {
        [GETTER.USER]: state => state.user,
        [GETTER.USER_ID]: state => state.user.id,
        [GETTER.TOKEN]: state => state.token
    },
    mutations: {
        [MUTATION.SET_TOKEN]: (state, { token }) => state.token = token,
        [MUTATION.SET_USER]: (state, { user }) => state.user = user
    },
    actions: {
        async [ACTION.LOGIN]({ commit }, { credentials }) {
            const response = await login(credentials)

            commit(MUTATION.SET_TOKEN, { token: response.data.token })
            commit(MUTATION.SET_USER, { user: response.data.user })

            return response
        },
        async [ACTION.LOGOUT]({ commit }) {
            return await logout()
        },
        async [ACTION.CLEAR]({ commit }) {
            commit(MUTATION.SET_TOKEN, { token: null })
            commit(MUTATION.SET_USER, { user: null })
        }
    }
}
