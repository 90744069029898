<template>
    <div class="page dashboard">
      <carbo-heading title="Dashboard" subtitle="Welcome back to Carbo!" />
    </div>
</template>

<script>
  export default {
    name: 'Dashboard'
  }
</script>

<style scoped>

</style>
