<template>
  <div class="page form user">
    <carbo-heading title="Create User"
                   subtitle="Please, provide following details, to create new user"/>
    <form @submit.prevent="onSubmit">
      <user-fields v-model="model" :errors="errors" />
      <div class="actions">
        <button class="btn">Create</button>
      </div>
    </form>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'

  import Fields from './Fields'
  import { createNamespacedHelpers } from 'vuex'
  import { CREATE_USER } from '../../../store/users/types.action'

  const users = createNamespacedHelpers('users')

  export default {
    name: 'Create',
    data() {
      return {
        model: {
          email: '',
          name: '',
          password: '',
          repeatPassword: '',
          permissions: []
        },
        errors: {}
      }
    },
    components: {
      'user-fields': Fields
    },
    methods: {
      ...users.mapActions({
        createUser: CREATE_USER
      }),
      async onSubmit () {
        try {
          await this.createUser({ model: cloneDeep(this.model) })
          await this.$router.push('/settings')
          await this.$showToast(`User ${ this.model.name } created.`, 'success')
        } catch(e) {
          if (!e.response) throw e
          this.errors = e.response.data.errors
        }
      }
    }
  }
</script>

<style scoped>

</style>
