import * as GETTER from './types.getter'
import * as MUTATION from './types.mutation'

import { getNavigation } from '../../services/navigation'

let requestCount = 0

export default {
    namespaced: true,
    state: {
        isFetching: false,
        navigation: getNavigation()
    },
    getters: {
        [GETTER.NAVIGATION]: state => state.navigation,
        [GETTER.IS_FETCHING]: state => state.isFetching
    },
    mutations: {
        [MUTATION.REQUEST_GLOBAL]: state => {
            state.isFetching = true
            requestCount++
        },
        [MUTATION.SUCCESS_GLOBAL]: state => {
            requestCount--
            if (requestCount === 0) state.isFetching = false
        },
        [MUTATION.FAILURE_GLOBAL]: state => {
            requestCount--
            if (requestCount === 0) state.isFetching = false
        }
    }
}
