import Pagination, { NEXT, PREV } from '../Pagination/Pagination'

export default {
  name: 'DataTable',

  props: {
    perPage: {
      type: Number,
      default: 12
    }
  },

  data () {
    return {
      selectedPage: 0
    }
  },

  render (h) {
    const perPage = this.perPage
    const children = this.$slots.default

    const head = children
      .filter(i => i.componentOptions && i.componentOptions.tag === 'carbo-data-table-head')
    const rows = children
      .filter(i => i.componentOptions && i.componentOptions.tag === 'carbo-data-table-row')

    if (!rows.length) {
      rows.push(h('tr', [
        h('td', { class: 'text-center', attrs: { colspan: head[0].componentOptions.propsData.columns.length } }, [
          'No entries found.'
        ])
      ]))
    }

    const pageCount = Math.ceil(rows.length/perPage)
    const selectedPage = Math.min(this.selectedPage, pageCount-1)

    return h('div', { 'class': 'data-table' }, [
      h('table', { 'class': 'table' }, [
        h('thead', head),
        h('tbody', [
          ...rows.slice(selectedPage * perPage, (selectedPage + 1) * perPage)
        ])
      ]),
      h(Pagination, {
        props: {
          onSelect: this.onSelect.bind(this),
          selectedIndex: selectedPage,
          pageCount
        },
      })
    ])
  },

  methods: {
    onSelect (index) {
      if (index === NEXT) this.selectedPage += 1
      if (index === PREV) this.selectedPage -= 1

      if (typeof(index) === 'number') this.selectedPage = index
    }
  }

}
