export default {
  name: 'Tabs',
  props: {
    startIndex: {
      default: 0,
      type: Number
    }
  },
  data () {
    return {
      selectedIndex: this.startIndex
    }
  },
  methods: {
    goTo (index) {
      this.selectedIndex = index
    }
  },
  render (h) {
    const tabs = this.$slots.default.filter(i => i.componentOptions)
    const navItems = tabs.map((tab, currentIndex) =>
      h('li', {
        'role': 'tab',
        'class': `
          tabs-nav-item 
          ${(this.selectedIndex === currentIndex) ? 'active' : ''}
          ${(tab.componentOptions.propsData.disabled) ? 'disabled' : ''}`,
        on: {
          'click': () => this.goTo(currentIndex)
        } }, [tab.componentOptions.propsData.title]))
    const selectedTab = tabs[this.selectedIndex]

    return h('div', { 'class': 'tabs', 'role': 'tabs' }, [
      h('ul', { 'class': 'tabs-nav' }, navItems),
      h('div', { 'class': 'tabs-content' }, [
        selectedTab
      ])
    ])
  }
}
