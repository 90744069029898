export const SET_USER = 'setUser'
export const SET_TOKEN = 'setToken'

export const REQUEST_LOGIN = 'requestLogin'
export const SUCCESS_LOGIN = 'successLogin'
export const FAILURE_LOGIN = 'failureLogin'

export const REQUEST_LOGOUT = 'requestLogout'
export const SUCCESS_LOGOUT = 'successLogout'
export const FAILURE_LOGOUT = 'failureLogout'
