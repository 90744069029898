export const REQUEST_FETCH_USERS = 'requestFetchUsers'
export const SUCCESS_FETCH_USERS = 'successFetchUsers'
export const FAILURE_FETCH_USERS = 'failureFetchUsers'

export const REQUEST_CREATE_USER = 'requestCreateUser'
export const SUCCESS_CREATE_USER = 'successCreateUser'
export const FAILURE_CREATE_USER = 'failureCreateUser'

export const REQUEST_UPDATE_USER = 'requestUpdateUser'
export const SUCCESS_UPDATE_USER = 'successUpdateUser'
export const FAILURE_UPDATE_USER = 'failureUpdateUser'

export const REQUEST_DELETE_USER = 'requestDeleteUser'
export const SUCCESS_DELETE_USER = 'successDeleteUser'
export const FAILURE_DELETE_USER = 'failureDeleteUser'
