<template>
    <div class="fields">
      <carbo-form-field name="source" label="Source" v-model="model.source" />
      <carbo-form-validation-error v-if="errors.source" :errors="errors.source" />

      <carbo-form-field name="destination" label="Destination" v-model="model.destination" />
      <carbo-form-validation-error v-if="errors.destination" :errors="errors.destination" />

      <carbo-form-select name="status" label="Status" v-model="model.status" :options="[
        { value: 'enabled', label: 'Enabled' },
        { value: 'disabled', label: 'Disabled' }
      ]" />
      <carbo-form-validation-error v-if="errors.status" :errors="errors.status" />
    </div>
</template>

<script>
  export default {
    name: 'Fields',
    props: {
      value: Object,
      errors: Object
    },
    data() {
      return {
        model: this.value
      }
    },
    watch: {
      model() {
        this.$emit('input', this.model)
      }
    }
  }
</script>

<style scoped>

</style>
