import Vue from 'vue'
import VueRouter from 'vue-router'

import routesApp from './app'
import routesEmail from './email'
import routesInformationBoard from './informationBoard'

import { guardAuth, guardNoAuth, guardPermissions } from '../services/authorization'

Vue.use(VueRouter)

const createRouter = () => {
    const routes = [
        ...routesInformationBoard,
        ...routesEmail,

        // app routes must be last...
        ...routesApp,
    ]

    const router = new VueRouter({
        mode: 'history',
        routes
    })

    router.beforeEach(guardAuth)
    router.beforeEach(guardNoAuth)
    router.beforeEach(guardPermissions)

    return router
}

export default createRouter
