<template>
    <carbo-modal>
      <div class="prompt">
        <slot />
      </div>
      <div class="actions">
        <button class="btn" @click.prevent="onCancel">Cancel</button>
        <button class="btn" :class="acceptVariantClass" @click.prevent="onAccept">{{ acceptLabel }}</button>
      </div>
    </carbo-modal>
</template>

<script>
  export default {
    name: 'ConfirmationModal',
    props: {
      acceptLabel: {
        type: String,
        default: 'Accept'
      },
      acceptVariant: String,

      onCancel: Function,
      onAccept: Function
    },
    computed: {
      acceptVariantClass()  {
        if (!this.acceptVariant) return null
        return {
          [`btn-${this.acceptVariant}`]: true
        }
      }
    }
  }
</script>

<style scoped>
  .actions {
    margin-top: 2rem;
    text-align: right;
  }
</style>
