import axios from 'axios'

export const getBoards = async () => {
    return await axios.get('/api/boards')
}

export const createBoard = async ({ model }) => {
    return await axios.post('/api/boards/create', model)
}

export const updateBoard = async ({ model }) => {
    return await axios.patch(`/api/boards/update/${model.id}`, model)
}

export const deleteBoard = async ({ id }) => {
    return await axios.delete(`/api/boards/delete/${id}`)
}

export const getEntries = async ({ slug }) => {
    return await axios.get(`/api/boards/${slug}/entry`)
}

export const sortEntries = async ({ slug, sortOrder }) => {
    return await axios.patch(`/api/boards/entry/sort`, sortOrder)
}

export const createEntry = async ({ slug, model }) => {
    return await axios.post(`/api/boards/entry/create`, model)
}

export const updateEntry = async ({ slug, model }) => {
    return await axios.patch(`/api/boards/entry/update/${model.id}`, model)
}

export const deleteEntry = async ({ slug, id }) => {
    return await axios.delete(`/api/boards/entry/delete/${id}`)
}
