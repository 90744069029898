import { getAvailablePermissions } from '../../services/settings'

import * as GETTER from './types.getter'
import * as MUTATION from './types.mutation'
import * as ACTION from './types.action'

export default {
    namespaced: true,
    state: {
        data: [],
        isFetching: false
    },
    getters: {
        [GETTER.DATA]: state => state.data,
        [GETTER.IS_FETCHING]: state => state.isFetching
    },
    mutations: {
        [MUTATION.REQUEST_FETCH_PERMISSIONS]: state => state.isFetching = true,
        [MUTATION.SUCCESS_FETCH_PERMISSIONS]: (state, { permissions }) => {
            state.isFetching = false
            state.data = permissions
        },
        [MUTATION.FAILURE_FETCH_PERMISSIONS]: state => state.isFetching = false
    },
    actions: {
        async [ACTION.FETCH_PERMISSIONS]({ commit }) {
            commit(MUTATION.REQUEST_FETCH_PERMISSIONS)

            try {
                const response = await getAvailablePermissions()
                commit(MUTATION.SUCCESS_FETCH_PERMISSIONS, { permissions: response.data })

                return response
            } catch(e) {
                commit(MUTATION.FAILURE_FETCH_PERMISSIONS)
                throw e
            }
        }
    }
}
