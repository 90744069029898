<template>
    <div class="heading">
      <h1 class="content-title">{{ title }}</h1>
      <h2 class="content-subtitle">{{ subtitle }}</h2>
    </div>
</template>

<script>
  export default {
    name: 'Heading',
    props: {
      title: String,
      subtitle: String
    }
  }
</script>

<style scoped>

</style>
