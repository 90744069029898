import cloneDeep from 'lodash/cloneDeep'

import COMMON from './index'

/**
 * Generic CRUD store
 */
export default ($fetch, $create, $update, $delete) => ({
    state: {
        isFetching: false,
        data: [],
        error: null
    },
    getters: {
        [COMMON.GETTER.ITEM]: state => id => state.data.find(i => i.id === parseInt(id)),
        [COMMON.GETTER.ITEM_INDEX]: state => id => state.data.findIndex(i => i.id === parseInt(id)),
        [COMMON.GETTER.COUNT]: state => state.data.length
    },
    mutations: {
        [COMMON.MUTATION.REQUEST_FETCH]: state => {
            state.isFetching = true
            state.error = null
        },
        [COMMON.MUTATION.SUCCESS_FETCH]: (state, { data }) => {
            state.isFetching = false
            state.data = data
        },
        [COMMON.MUTATION.FAILURE_FETCH]: (state, { error }) => {
            state.isFetching = false
            state.data = null
            state.error = error
        },

        [COMMON.MUTATION.REQUEST_CREATE]: (state, { temporaryItem }) => {
            state.data.push(temporaryItem)
        },
        [COMMON.MUTATION.SUCCESS_CREATE]: (state, { item, temporaryItem }) => {
            state.data.map(i => i === temporaryItem ? item : i)
        },
        [COMMON.MUTATION.FAILURE_CREATE]: (state, { temporaryItem }) => {
            state.data.splice(state.data.indexOf(temporaryItem), 1)
        },

        [COMMON.MUTATION.REQUEST_UPDATE]: (state, { temporaryItem }) => {
            state.data.map(i => i.id === temporaryItem.id ? temporaryItem : i)
        },
        [COMMON.MUTATION.SUCCESS_UPDATE]: (state, { item, temporaryItem }) => {
            state.data.map(i => i === temporaryItem ? item : i)
        },
        [COMMON.MUTATION.FAILURE_UPDATE]: (state, { temporaryItem, originalItem, error }) => {
            state.data.map(i => i === temporaryItem ? originalItem : i)
        },

        [COMMON.MUTATION.REQUEST_DELETE]: (state, { index }) => {
            state.data.splice(index, 1)
        },
        [COMMON.MUTATION.SUCCESS_DELETE]: state => {
        },
        [COMMON.MUTATION.FAILURE_DELETE]: (state, { error, originalItem, index }) => {
            state.data = [...state.data.slice(0, index), originalItem, ...state.data.slice(index)]
        }
    },
    actions: {
        async [COMMON.ACTION.FETCH]({ commit }, options) {
            commit(COMMON.MUTATION.REQUEST_FETCH)

            try {
                const response = await $fetch(options)
                commit(COMMON.MUTATION.SUCCESS_FETCH, { data: response.data })

                return response
            } catch (e) {
                commit(COMMON.MUTATION.FAILURE_FETCH, { error: e.message })
                throw e
            }
        },
        async [COMMON.ACTION.CREATE]({ commit }, options) {
            const { model } = options
            commit(COMMON.MUTATION.REQUEST_CREATE, { temporaryItem: model })

            try {
                const response = await $create(options)
                commit(COMMON.MUTATION.SUCCESS_CREATE, { item: response.data, temporaryItem: model })

                return response
            } catch (e) {
                commit(COMMON.MUTATION.FAILURE_CREATE, { error: e.message, temporaryItem: model })
                throw e
            }
        },
        async [COMMON.ACTION.UPDATE]({ commit, getters }, options) {
            const { model } = options
            const originalItem = cloneDeep(getters[COMMON.GETTER.ITEM](model.id))
            commit(COMMON.MUTATION.REQUEST_UPDATE, { temporaryItem: model })

            try {
                const response = await $update(options)
                commit(COMMON.MUTATION.SUCCESS_UPDATE, { temporaryItem: model, item: response.data })

                return response
            } catch (e) {
                commit(COMMON.MUTATION.FAILURE_UPDATE, {
                    error: e.message,
                    temporaryItem: model,
                    originalItem: originalItem
                })
                throw e
            }
        },
        async [COMMON.ACTION.DELETE]({ commit, getters }, options) {
            const { id } = options
            const index = getters[COMMON.GETTER.ITEM_INDEX](id)
            const originalItem = getters[COMMON.GETTER.ITEM](id)

            commit(COMMON.MUTATION.REQUEST_DELETE, { index })

            try {
                const response = await $delete(options)
                commit(COMMON.MUTATION.SUCCESS_DELETE)

                return response
            } catch (e) {
                commit(COMMON.MUTATION.FAILURE_DELETE, { index, originalItem })
                throw e
            }
        }
    }
})
