<template>
    <div class="fields">
      <carbo-form-field name="email" label="E-mail" type="email" v-model="model.email" />
      <carbo-form-validation-error v-if="errors.email" :errors="errors.email"/>

      <carbo-form-field name="name" label="Full name" v-model="model.name" />
      <carbo-form-validation-error v-if="errors.name" :errors="errors.name"/>

      <carbo-form-field name="password" label="Password" type="password" v-model="model.password" />
      <carbo-form-validation-error v-if="errors.password" :errors="errors.password"/>

      <carbo-form-field name="repeatPassword" label="Repeat password" type="password"
                        v-model="model.repeatPassword" />
      <carbo-form-validation-error v-if="errors.repeatPassword" :errors="errors.repeatPassword"/>

      <carbo-form-multi-assign name="permissions" label="Permissions" :options="availablePermissions"
                               v-model="model.permissions" />
      <carbo-form-validation-error v-if="errors.permissions" :errors="errors.permissions"/>
    </div>
</template>

<script>
  import transform from 'lodash/transform'
  import { createNamespacedHelpers } from 'vuex'
  import { FETCH_PERMISSIONS } from '../../../store/permissions/types.action'

  const permissions = createNamespacedHelpers('permissions')

  export default {
    name: 'Form',
    props: {
      value: Object,
      errors: Object
    },
    data () {
      return {
        model: this.value
      }
    },
    beforeMount() {
      this.fetchPermissions()
    },
    watch: {
      model: () => this.onModelChanged
    },
    methods: {
      ...permissions.mapActions({
        fetchPermissions: FETCH_PERMISSIONS
      }),
      onModelChanged() {
        this.$emit('input', this.model)
      }
    },
    computed: {
      ...permissions.mapState({
        availablePermissions: state => transform(state.data, (acc, value, key) => {
          acc.push({ value: key, label: value.description })
          return acc
        }, [])
      })
    }
  }
</script>

<style scoped>

</style>
