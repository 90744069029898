<template>
    <ul>
      <li v-for="error in errors" class="color-failure validation-error">
        {{ error }}
      </li>
    </ul>
</template>

<script>
  export default {
    name: 'FormValidationError',
    props: {
      errors: Array
    }
  }
</script>

<style scoped>

</style>
