<template>
  <portal-target name="carbo-modal-target" class="modal-wrapper" multiple />
</template>

<script>
  export default {
    name: 'ModalTarget'
  }
</script>

<style scoped>

</style>
