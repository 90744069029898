<template>
  <div class="fields">
    <quill style="margin-bottom:6rem;height:400px" v-model="model.content" output="html" :config="quillConfig" />

    <carbo-form-select name="status" label="Status" v-model="model.status" :options="[
      { value: 'info', label: 'Info' },
      { value: 'success', label: 'Success' },
      { value: 'failure', label: 'Failure' }
    ]" />
  </div>
</template>

<script>
  import Vue from 'vue'
  import VueQuill from 'vue-quill'

  Vue.use(VueQuill)

  export default {
    name: 'Fields',
    props: {
      value: Object,
      errors: Object
    },
    data() {
      return {
        model: this.value,
        quillConfig: {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
              ['blockquote', 'code-block'],

              [{ 'header': 1 }, { 'header': 2 }],               // custom button values
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
              [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
              [{ 'direction': 'rtl' }],                         // text direction

              [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              [{ 'font': [] }],
              [{ 'align': [] }],

              ['clean']                                         // remove formatting button
            ]
          }
        }
      }
    },
    watch: {
      model() {
        this.$emit('input', this.model)
      }
    }
  }
</script>

<style scoped>
  @import "https://cdn.quilljs.com/1.2.6/quill.snow.css";
</style>
