import MasterLayout from '../components/Layouts/MasterLayout'

import Login from '../components/Pages/Login'
import Settings from '../components/Pages/Settings'

import Permissions from '../permissions'
import Dashboard from '../components/Pages/Dashboard'

import UserCreate from '../components/Pages/User/Create'
import UserUpdate from '../components/Pages/User/Update'

export default [
    {
        path: '/login',
        component: Login,
        meta: {
            noAuth: true
        }
    },
    {
        path: '/',
        component: MasterLayout,
        meta: {
            auth: true
        },
        children: [
            {
                path: '/',
                component: Dashboard
            },

            // Users
            {
                path: '/settings',
                component: Settings,
                meta: {
                    permissions: [ Permissions.app.ACCESS_SETTINGS ]
                }
            },
            {
                path: '/settings/user/create',
                component: UserCreate,
                meta: {
                    permissions: [ Permissions.app.MANAGE_USERS ]
                }
            },
            {
                path: '/settings/user/update/:id',
                component: UserUpdate,
                meta: {
                    permissions: [ Permissions.app.MANAGE_USERS ]
                }
            },
        ]
    }
]
