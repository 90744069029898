import MasterLayout from '../components/Layouts/MasterLayout'

import MailboxList from '../components/Pages/Email/Mailbox/List'
import MailboxCreate from '../components/Pages/Email/Mailbox/Create'
import MailboxUpdate from '../components/Pages/Email/Mailbox/Update'

import DomainList from '../components/Pages/Email/Domain/List'
import DomainCreate from '../components/Pages/Email/Domain/Create'
import DomainUpdate from '../components/Pages/Email/Domain/Update'

import AliasList from '../components/Pages/Email/Alias/List'
import AliasCreate from '../components/Pages/Email/Alias/Create'
import AliasUpdate from '../components/Pages/Email/Alias/Update'

import Permissions from '../permissions'

export default [
    {
        path: '/e-mail',
        component: MasterLayout,
        meta: {
            auth: true
        },
        children: [
            {
                path: '/e-mail/mailbox',
                component: MailboxList,
                meta: {
                    permissions: [ Permissions.email.MANAGE_MAILBOXES ]
                }
            },
            {
                path: '/e-mail/mailbox/create',
                component: MailboxCreate,
                meta: {
                    permissions: [ Permissions.email.MANAGE_MAILBOXES ]
                }
            },
            {
                path: '/e-mail/mailbox/update/:id',
                component: MailboxUpdate,
                meta: {
                    permissions: [ Permissions.email.MANAGE_MAILBOXES ]
                }
            },

            {
                path: '/e-mail/alias',
                component: AliasList,
                meta: {
                    permissions: [ Permissions.email.MANAGE_ALIASES ]
                }
            },
            {
                path: '/e-mail/alias/create',
                component: AliasCreate,
                meta: {
                    permissions: [ Permissions.email.MANAGE_ALIASES ]
                }
            },
            {
                path: '/e-mail/alias/update/:id',
                component: AliasUpdate,
                meta: {
                    permissions: [ Permissions.email.MANAGE_ALIASES ]
                }
            },

            {
                path: '/e-mail/domain',
                component: DomainList,
                meta: {
                    permissions: [ Permissions.email.MANAGE_DOMAINS ]
                }
            },
            {
                path: '/e-mail/domain/create',
                component: DomainCreate,
                meta: {
                    permissions: [ Permissions.email.MANAGE_DOMAINS ]
                }
            },
            {
                path: '/e-mail/domain/update/:id',
                component: DomainUpdate,
                meta: {
                    permissions: [ Permissions.email.MANAGE_DOMAINS ]
                }
            }
        ]
    }
]
