<template>
  <div class="fields">
    <carbo-form-field name="name" label="Name" v-model="model.name" />
    <carbo-form-validation-error v-if="errors.name" :errors="errors.name" />

    <carbo-form-field name="slug" label="Access key" v-model="model.slug" />
    <carbo-form-validation-error v-if="errors.slug" :errors="errors.slug" />
  </div>
</template>

<script>
  export default {
    name: 'Fields',
    props: {
      value: Object,
      errors: Object
    },
    data() {
      return {
        model: this.value,
      }
    },
    watch: {
      model() {
        this.$emit('input', this.model)
      }
    }
  }
</script>

