<template>
  <div class="preloader" :class="{ visible: cachedVisible, left, right }"></div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { IS_FETCHING } from '../../store/app/types.getter'

  const app = createNamespacedHelpers('app')

  let preloaderInterval = null
  let showDelay = null

  export default {
    name: 'Preloader',
    watch: {
      visible (value) {
        if (value && !showDelay) {
          showDelay = setTimeout(() => this.cachedVisible = true, 300)
        } else if (!value && showDelay) {
          this.cachedVisible = false
          clearTimeout(showDelay)
          showDelay = null
        }

        if (!value) clearInterval(preloaderInterval)
        else {
          const handler = () => {
            this.left = !this.left
            this.right = !this.right
          }
          preloaderInterval = setInterval(handler, 500)
        }
      }
    },
    computed: {
      ...app.mapGetters({
        visible: IS_FETCHING
      })
    },
    data () {
      return {
        left: true,
        right: false,
        cachedVisible: false
      }
    }
  }
</script>

<style scoped>

</style>
