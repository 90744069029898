import './bootstrap'

import Vue from 'vue'
import VuePortal from 'portal-vue'

import { sync } from 'vuex-router-sync'

import createRouter from './router'
import createStore  from './store'

import toastNotificationsMixin from './mixins/toastNotifications'
import formatMixin from './mixins/format'
import authMixin from './mixins/auth'

import './components'

Vue.use(VuePortal)

Vue.mixin(toastNotificationsMixin)
Vue.mixin(formatMixin)
Vue.mixin(authMixin)

const router = createRouter()
const store = createStore()

const unsync = sync(store, router, {})
const app = new Vue({
    el: '#app',
    render: (h) => h(require('./components/App.vue').default),
    router,
    store
})

export { router, store, unsync, app }
