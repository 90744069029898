<template>
  <div class="page form alias">
    <carbo-heading title="Create Alias"
                   subtitle="Create new alias record"/>
    <form @submit.prevent="onSubmit">
      <alias-fields v-model="model" :errors="errors" />
      <div class="actions">
        <button class="btn">Create Alias</button>
      </div>
    </form>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../../store/common'
  import Fields from './Fields'

  const alias = createNamespacedHelpers('email/alias')

  export default {
    name: 'Create',
    components: {
      aliasFields: Fields
    },
    data() {
      return {
        model: {
          status: 'disabled',
          created_by: this.$store.getters['auth/userId']
        },
        errors: {}
      }
    },
    methods: {
      async onSubmit() {
        try {
          await this.create({ model: this.model })
          await this.$router.push('/e-mail/alias')
          await this.$showToast('Alias created successfully!', 'success')
        } catch(e) {
          if (!e.response) throw e
          this.errors = e.response.data.errors
        }
      },
      ...alias.mapActions({
        create: COMMON.ACTION.CREATE
      })
    }
  }
</script>

<style scoped>

</style>
