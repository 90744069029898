import Permissions from '../../permissions'

export default {
    email: {
        title: 'E-mail',
        items: [
            { title: 'Mailboxes', link: '/e-mail/mailbox', permissions: [ Permissions.email.MANAGE_MAILBOXES ] },
            { title: 'Domains', link: '/e-mail/domain', permissions: [ Permissions.email.MANAGE_DOMAINS ] },
            { title: 'Aliases', link: '/e-mail/alias', permissions: [ Permissions.email.MANAGE_ALIASES ] }
        ]
    }
}
