<template>
  <div class="page board-entry">
    <carbo-heading :title="`Content entries for ${board.name}`"
                   subtitle="Manage content of information board" />

    <div class="actions">
      <router-link class="btn" to="entry/create">Add Entry</router-link>
    </div>

    <draggable v-if="entries.length" class="entry-list" v-model="entries" group="entries">
      <div class="entry" v-for="entry in entries" :key="entry.id">
        <div class="content" v-html="entry.content"></div>
        <div class="info">
          {{ entry.status }} |
          {{ $formatDate(entry.updated_at) }} |
          {{ $formatUser(entry.created_by) }}
        </div>
        <div class="actions">
          <router-link class="btn" :to="`entry/update/${entry.id}`">Edit</router-link>
          <a class="btn btn-failure" href="#delete" @click.prevent="toDelete = entry">Delete</a>
        </div>
      </div>
    </draggable>
    <p v-else>No entries present.</p>

    <carbo-confirmation v-if="toDelete"
                        accept-variant="failure"
                        accept-label="Delete"
                        :on-accept="onAccept"
                        :on-cancel="onCancel">
      Do you really wish to delete board entry?<br>
      This action <strong>can not</strong> be undone.
    </carbo-confirmation>
  </div>
</template>

<script>
  import orderBy from 'lodash/orderBy'

  import { createNamespacedHelpers } from 'vuex'
  import { store } from '../../../../app'
  import COMMON from '../../../../store/common'
  import draggable from 'vuedraggable'
  import { SORT } from '../../../../store/board/entry/types.action'

  const entry = createNamespacedHelpers('board/entry')

  export default {
    name: 'List',
    components: {
      draggable
    },
    beforeRouteEnter(to, from, next) {
      if (!store.getters['board/count']) {
        store.dispatch('board/fetch')
          .then(() => next())
          .catch(() => next('/board'))
      }
      else next()
    },
    beforeMount() {
      this.fetch({ slug: this.board.slug })
    },
    data() {
      const board = this.$store.getters['board/itemBySlug'](this.$route.params.slug)
      return {
        board,
        toDelete: null
      }
    },
    computed: {
      entries: {
        get() {
          return orderBy(
            this.$store.state.board.entry.data.filter(i => i.board_id === this.board.id),
            ['sort_order'], ['desc'])
        },
        async set(value) {
          try {
            await this.sort({ sortOrder: value.reduce((acc, current, index) => {
              acc[current.id] = value.length - index
              return acc
              }, {}) })
            await this.$showToast('Sort updated successfully!', 'success')
          } catch(e) {
            this.$showToast(`Failed to update sort: ${e.message}`, 'failure')
          }
        }
      },
      ...entry.mapState({
        isFetching: state => state.isFetching,
        error: state => state.error
      })
    },
    methods: {
      async onAccept() {
        const item = this.toDelete
        this.toDelete = null

        try {
          await this.delete({ id: item.id })
          await this.$showToast('Entry deleted successfully!', 'success')
        } catch(e) {
          await this.$showToast(`Failed to delete entry: ${e.message}`, 'failure')
        }
      },
      onCancel() {
        this.toDelete = null
      },
      ...entry.mapActions({
        fetch: COMMON.ACTION.FETCH,
        delete: COMMON.ACTION.DELETE,
        sort: SORT
      })
    }
  }
</script>

<style>
  .entry-list .entry {
    background-color: #EEE;
    padding:  1.5rem;
    margin: .25rem 0;
    cursor: move;
  }

  .entry-list .entry .actions {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .entry-list .entry .content { margin-bottom: 1rem }

  .entry-list .entry p:only-child { margin: 0; }
  .entry-list .entry p:first-child { margin-top: 0 }
  .entry-list .entry p:last-child { margin-bottom: 0 }
</style>
