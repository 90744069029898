import { router, store } from './app'
import { clearCache, getAuthorizationToken, logout } from './services/authorization'

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window.axios.interceptors.response.use(response => response, err => {
    if (!err.response || !getAuthorizationToken()) return Promise.reject(err)
    if (err.response.status !== 401) return Promise.reject(err)

    clearCache()
    router.push('/login')
})

window.axios.interceptors.request.use(request => {
    const token = getAuthorizationToken()

    if (!token) return request

    request.headers['Authorization'] = `Bearer ${token}`
    return request
})

window.axios.interceptors.request.use(request => {
    store.commit('app/requestGlobal')
    return request
})

window.axios.interceptors.response.use(response => {
    store.commit('app/successGlobal')
    return response
}, error => {
    store.commit('app/failureGlobal')
    return Promise.reject(error)
})

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
