<template>
    <div class="page domain">
      <carbo-heading title="Domains" subtitle="Manage domains"/>

      <div class="actions">
        <router-link to="/e-mail/domain/create" class="btn">Add Domain</router-link>
      </div>

      <carbo-data-table>
        <carbo-data-table-head :columns="['Status', 'Domain', 'Created by', 'Last change', 'Actions']" />
        <carbo-data-table-row v-for="domain in domains" :key="domain.id">
          <td style="width: 100px; font-weight: bold" :class="$classesStatus(domain.status)">{{ $formatStatus(domain.status) }}</td>
          <td>{{ domain.domain }}</td>
          <td>{{ $formatUser(domain.created_by) }}</td>
          <td>{{ $formatDate(domain.updated_at) }}</td>
          <td>
            <router-link :to="`/e-mail/domain/update/${domain.id}`">Edit</router-link> |
            <a href="#delete" class="color-failure" @click.prevent="e => toDelete = domain">Delete</a>
          </td>
        </carbo-data-table-row>
      </carbo-data-table>

      <carbo-confirmation v-if="toDelete"
                          accept-variant="failure"
                          accept-label="Delete"
                          :on-accept="onDelete"
                          :on-cancel="onCancel">
        Do you really wish to delete domain <strong>{{ toDelete.domain }}</strong>?<br>
        This action <strong>can not</strong> be undone.
      </carbo-confirmation>
    </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../../store/common'
  import format from '../../../../mixins/email/format'

  const domain = createNamespacedHelpers('email/domain')

  export default {
    name: 'Domain',
    mixins: [format],
    beforeMount() {
      this.fetch()
    },
    computed: {
      ...domain.mapState({
        domains: state => state.data,
        isFetching: state => state.isFetching,
        error: state => state.error
      })
    },
    data() {
      return {
        toDelete: null
      }
    },
    methods: {
      async onDelete() {
        const item = this.toDelete
        this.toDelete = null

        try {
          await this.delete({ id: item.id })
          await this.$showToast(`Domain "${item.domain}" deleted successfully!`, 'success')
        } catch(e) {
          await this.$showToast(`Failed to delete domain: ${e.message}`, 'failure')
        }
      },
      onCancel() {
        this.toDelete = null
      },
      ...domain.mapActions({
        fetch: COMMON.ACTION.FETCH,
        delete: COMMON.ACTION.DELETE
      })
    }
  }
</script>

<style scoped>

</style>
