import * as MUTATION from './types.mutation'
import * as ACTION from './types.action'

export default {
    namespaced: true,
    state: {
        toasts: []
    },
    mutations: {
        [MUTATION.ADD_TOAST]: (state, { toast }) => state.toasts.push(toast),
        [MUTATION.REMOVE_TOAST]: (state, { toast }) => state.toasts.splice(state.toasts.indexOf(toast), 1)
    },
    actions: {
        [ACTION.SHOW_TOAST]({ commit }, { toast, time }) {
            commit(MUTATION.ADD_TOAST, { toast })
            return new Promise(resolve => {
                setTimeout(() => {
                    commit(MUTATION.REMOVE_TOAST, { toast })
                }, time*1000)
            })
        }
    }
}
