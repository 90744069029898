<template>
    <div class="form-group">
      <label :for="id">{{ label }}:</label>
      <select :name="name"
              class="form-control"
              :id="id"
              v-model="model"
              @change="$emit('input', model)">
        <option v-for="option in options"
                :value="option.value">{{ option.label }}</option>
      </select>
    </div>
</template>

<script>
  export default {
    name: 'FormSelect',
    props: {
      name: String,
      label: String,
      value: null,
      options: Array
    },
    data () {
      return {
        id: this.$options.name + this.name,
        model: this.value
      }
    }
  }
</script>

<style scoped>

</style>
