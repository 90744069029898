<template>
    <aside class="sidebar">
      <carbo-preloader />
      <div class="title clearfix va-middle">
        <router-link to="/"><h1 class="text-uppercase brand-name float-left">Carbo</h1></router-link>
        <router-link class="float-right"
                     v-if="$hasPermission($permissions.app.ACCESS_SETTINGS)"
                     to="/settings">Settings</router-link>
      </div>
      <div class="category" v-for="(group, key) in visibleGroups" :key="key">
        <span>{{ group.title }}</span>
        <ul class="items">
          <li v-for="item in group.items" :key="item.link"><router-link :to="item.link">{{ item.title }}</router-link></li>
        </ul>
      </div>
      <div class="footer va-bottom clearfix">
        <div>{{ user.name }} <small>({{ user.email }})</small></div>
        <div><a href="#" @click.prevent="onLogout">Logout</a></div>
      </div>
    </aside>
</template>

<script>
  import transform from 'lodash/transform'
  import cloneDeep from 'lodash/cloneDeep'
  import difference from 'lodash/difference'

  import { createNamespacedHelpers } from 'vuex'
  import { NAVIGATION } from '../../store/app/types.getter'
  import { USER } from '../../store/auth/types.getter'
  import { CLEAR, LOGOUT } from '../../store/auth/types.action'
  import { clearCache } from '../../services/authorization'

  const auth = createNamespacedHelpers('auth')
  const app = createNamespacedHelpers('app')

  export default {
    name: 'Navigation',
    methods: {
      ...auth.mapActions({
        logout: LOGOUT,
        clearAuth: CLEAR
      }),
      async onLogout() {
        await this.logout()
        clearCache()

        await this.$router.push('/login')
        this.clearAuth()
      },
      refreshNavigation () {
        if (!this.user) return {}

        this.visibleGroups = transform(cloneDeep(this.navigation), (acc, value, key) => {
          value.items = value.items.filter(i => !difference(i.permissions, this.user.permissions).length)

          if (!value.items.length) return acc

          acc[key] = value
          return acc
        }, {})
      }
    },
    mounted () {
      this.refreshNavigation()
    },
    watch: {
      navigation () { this.refreshNavigation() },
      user () { this.refreshNavigation() }
    },
    computed: {
      ...app.mapGetters({
        navigation: NAVIGATION,
      }),
      ...auth.mapGetters({
        user: USER
      }),
    },
    data () {
      return {
        visibleGroups: []
      }
    }
  }
</script>

<style scoped>

</style>
