<template>
    <div class="form-group">
      <label :for="id">{{ label }}:</label>
      <input class="form-control" :type="type" :name="name" :id="id" :value="value"
             @input="$emit('input', $event.target.value)">
    </div>
</template>

<script>
  export default {
    name: 'FormField',
    props: {
      type: String,
      name: String,
      label: String,
      value: null
    },
    data () {
      return {
        id: this.$options.name + this.name
      }
    }
  }
</script>

<style scoped>

</style>
