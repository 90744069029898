<template>
  <div class="page form alias">
    <carbo-heading :title="`Edit alias ${model.source}`"
                   subtitle="Update alias details" />

    <form @submit.prevent="onSubmit">
      <alias-fields v-model="model" :errors="errors" />

      <div class="actions">
        <button class="btn">Save changes</button>
      </div>
    </form>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import Fields from './Fields'
  import { store } from '../../../../app'
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../../store/common'

  const alias = createNamespacedHelpers('email/alias')

  export default {
    name: 'Update',
    components: {
      aliasFields: Fields
    },
    beforeRouteEnter(to, from, next) {
      if (!store.getters['email/alias/count']) {
        store.dispatch('email/alias/fetch')
          .then(() => next())
          .catch(() => next('/e-mail/alias'))
      }
      else next()
    },
    data() {
      const model = cloneDeep(this.$store.getters['email/alias/item'](this.$route.params.id))
      model.updated_by = this.$store.getters['auth/userId']
      
      return {
        model,
        errors: {}
      }
    },
    methods: {
      async onSubmit() {
        try {
          await this.update({ model: this.model })
          await this.$router.push('/e-mail/alias')
          await this.$showToast('Alias updated successfully!', 'success')
        } catch (e) {
          if (!e.response) throw e
          this.errors = e.response.data.errors
        }
      },
      ...alias.mapActions({
        update: COMMON.ACTION.UPDATE
      })
    }
  }
</script>
