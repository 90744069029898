<template>
    <div class="page settings">
      <carbo-heading title="Settings" subtitle="Manage Carbo internal settings"/>

      <carbo-tabs>
        <carbo-tab v-if="$hasPermission($permissions.app.MANAGE_USERS)" title="Users">
          <div class="actions">
            <router-link to="/settings/user/create" class="btn">Create User</router-link>
          </div>
          <carbo-data-table :per-page="6">
            <carbo-data-table-head  :columns="['Name', 'E-mail', 'Created at', 'Actions']" />
            <carbo-data-table-row v-for="user in users" :key="user.id">
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ $formatDate(user.created_at) }}</td>
              <td>
                <router-link :to="`/settings/user/update/${user.id}`">Edit</router-link> |
                <a href="#delete"
                   @click.prevent="e => toDelete = user"
                   class="color-failure" to="/">Delete</a>
              </td>
            </carbo-data-table-row>
          </carbo-data-table>
        </carbo-tab>
      </carbo-tabs>

      <carbo-confirmation v-if="toDelete"
                          accept-label="Delete"
                          accept-variant="failure"
                          :on-accept="onDeleteUser"
                          :on-cancel="() => toDelete = null">
        Do you really wish to delete user <strong>{{ toDelete.name }}</strong>?<br>
        This action <strong>can not</strong> be undone!
      </carbo-confirmation>
    </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { DELETE_USER, FETCH_USERS } from '../../store/users/types.action'
  import { DATA, ERROR, IS_FETCHING } from '../../store/users/types.getter'

  const users = createNamespacedHelpers('users')

  export default {
    name: 'Settings',
    async beforeMount() {
      await this.fetchUsers()
    },
    computed: users.mapGetters({
      isFetching: IS_FETCHING,
      users: DATA,
      error: ERROR
    }),
    methods: {
      async onDeleteUser() {
        const id = this.toDelete.id
        const name = this.toDelete.name
        this.toDelete = null

        try {
          await this.deleteUser({ id })
          this.$showToast(`User ${name} successfully deleted.`, 'success')
        } catch (e) {
          this.$showToast(`Failure: ${e.message}`, 'failure')
        }
      },
      ...users.mapActions({
        fetchUsers: FETCH_USERS,
        deleteUser: DELETE_USER
      })
    },
    data() {
      return { toDelete: null }
    }
  }
</script>

<style scoped>

</style>
