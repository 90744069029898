import capitalize from 'lodash/capitalize'

const $formatStatus = (status) => {
    return capitalize(status)
}

const $classesStatus = (status) => {
    switch (status) {
        case 'enabled':
            return { 'color-success': true }
        case 'disabled':
            return { 'color-failure': true }
    }
}

export default {
    methods: {
        $formatStatus,
        $classesStatus
    }
}
