import { SHOW_TOAST } from '../store/toasts/types.action'

export default {
    methods: {
        /**
         *
         * @param message
         * @param {'info'|'success'|'failure'} variant
         * @param time
         * @returns {Promise<void>}
         */
        async $showToast(message, variant = 'info', time = 4) {
            const toast = { message, variant }
            await this.$store.dispatch(`toasts/${SHOW_TOAST}`, { toast, time })
        }
    }
}
