import axios from 'axios'
import { transformUser } from '../common/transform'

export const getModules = async () => {
    return await axios.get('/api/settings/modules')
}

export const setModuleEnabled = async (codename, enabled) => {
    return await axios.post(`/api/settings/modules/${codename}`, { enabled })
}

export const getUsers = async () => {
    const response = await axios.get('/api/settings/user')
    response.data = response.data.map(transformUser)

    return response
}

export const createUser = async (model) => {
    const response =  await axios.post('/api/settings/user/create', model)
    response.data = transformUser(response.data)

    return response
}

export const updateUser = async (model) => {
    if (!model.password) delete model.password
    if (!model.repeatPassword) delete model.repeatPassword

    const response = await axios.patch(`/api/settings/user/update/${model.id}`, model)
    response.data = transformUser(response.data)

    return response
}

export const deleteUser = async (id) => {
    return await axios.delete(`/api/settings/user/delete/${id}`)
}

export const getAvailablePermissions = async () => {
    return await axios.get('/api/settings/permission/available')
}

export const getPermissions = async (userId) => {
    return await axios.get(`/api/settings/permission/${userId}`)
}

export const assignPermission = async (userId, codename) => {
    return await axios.post(`/api/settings/permission/${userId}`, { codename })
}

export const deletePermission = async (userId, codename) => {
    return await axios.delete(`/api/settings/permission/${userId}/${codename}`)
}
