<template>
    <div class="page board">
      <carbo-heading title="Information Boards"
                     subtitle="Manage information boards and their content" />

      <div class="actions">
        <router-link class="btn" to="/board/create">Create Information Board</router-link>
      </div>

      <carbo-data-table>
        <carbo-data-table-head :columns="['Name', 'Access key', 'Last change', 'Actions']" />
        <carbo-data-table-row v-for="board in boards" :key="board.id">
          <td>{{ board.name }}</td>
          <td>{{ board.slug }}</td>
          <td>{{ $formatDate(board.updated_at) }}</td>
          <td>
            <router-link :to="`/board/${board.slug}/entry`">Content</router-link> |
            <router-link :to="`/board/update/${board.id}`">Edit</router-link> |
            <a href="#delete" class="color-failure" @click.prevent="() => toDelete = board">Delete</a>
          </td>
        </carbo-data-table-row>
      </carbo-data-table>

      <carbo-confirmation v-if="toDelete"
                          accept-variant="failure"
                          accept-label="Delete"
                          :on-accept="onAccept"
                          :on-cancel="onCancel">
        Do you really want to delete board <strong>{{ toDelete.name }}</strong> with all content entries?<br>
        This action <strong>can not</strong> be undone.
      </carbo-confirmation>
    </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import COMMON from '../../../store/common'

  const board = createNamespacedHelpers('board')

  export default {
    name: 'List',
    beforeMount() {
      this.fetch()
    },
    data() {
      return {
        toDelete: null
      }
    },
    computed: {
      ...board.mapState({
        boards: state => state.data,
        isFetching: state => state.isFetching,
        error: state => state.error
      })
    },
    methods: {
      async onAccept() {
        const item = this.toDelete
        this.toDelete = null

        try {
          await this.delete({ id: item.id })
          await this.$showToast(`Board "${item.name}" deleted successfully!`, 'success')
        } catch (e) {
          await this.$showToast(`Failed to delete board: ${e.message}`, 'failure')
        }
      },
      onCancel() {
        this.toDelete = null
      },
      ...board.mapActions({
        fetch: COMMON.ACTION.FETCH,
        delete: COMMON.ACTION.DELETE
      })
    }
  }
</script>
